import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import { graphql } from 'gatsby';

import Article from '~/src/components/Article';

import Block from '~/src/components/Block';
import Figure from '~/src/components/Figure';

export const frontmatter = {
  id: 'stoovo',
  isWork: true,
  subtitle: 'Stoovo',
  title: 'Building a friendly fintech app, from scratch.',
  date: '2018-03-01',
  cover: './stoovo-preview.jpg',
  path: '/work/stoovo/',
  excerpt: ` During my two and a half years with Stoovo, I helped with everything that one could: from creating a visual identity to doing simplistic mobile interfaces and B2B dashboards.`,
  details: [
    {
      title: 'Responsibility',
      description: 'Product Design',
    },
    {
      title: 'Date',
      description: '2016-2019',
    },
    {
      title: 'Stack',
      description: 'Branding, UI/UX and Motion Design',
    },
  ],
};

export default props => (
  <Article {...props}>
    <Block mobilePull>
      <p>
        In June 2016, Hantz from Stoovo reached out to me and we talked about what Stoovo would later become. 
        During my two and a half years with Stoovo, I helped with everything 
        that one could: from creating a visual identity to doing simplistic mobile interfaces and B2B dashboards.</p>
      <p>
        
      </p>
    </Block>
    <Block align="right">
      <p>
        Being the first person to join the cofounders, the venture had a design-led approach where the whole focus was on the user, not VC-imposed KPI's. 
        I was incredibly lucky to have worked and learned from the whole team. 
      </p>
      <p>
        Meanwhile, the startup grew into a company, hired more people, impressed at Y Combinator and was finalist of StartupGrind because it managed to do fintech in a friendly and approachable way.
      </p>
    </Block>

    <hr />

    <Block align="center" pull>
      <h2>Behind the logo</h2>
      <p>
      Initially presented as  AI chatbot that analyses your expenses, 
      we sat down and worked on a visual identity that would later be a friendly face on your homescreen. 
      </p>
    </Block>

    <Block align="center">
      <Figure fluid={props.data.stoovoBrandingDrawing} />
    </Block>

    <Block>
      <Figure fluid={props.data.stoovoAppIcon} />
    </Block>
    <Block vc align="right">
      <h3>A nice-long identity process</h3>
      <p>
        Before launch, it took some time to draw app icons for the each environment & for the beta builds, the press kit and other promotional materials — but was fun!  
        You know those data-heavy dashboard in fintech? I didn't want people to focus on the UI in the marketing shots and rather promote the friendly, helpful face in Stoovo.  
      </p>
    </Block>

    <Block vc>
      <h3>Leave no stone unturned</h3>
      <p>
        With different scenarios of tone and personality, we had multiple branding explorations and ended up using the chat-parrot face. The cat says hello.
      </p>
    </Block>

    <Block vc align="right">
      <Figure link fluid={props.data.stoovoIterations} />
    </Block>

    <hr />

    <Block align="center">
      <h2>The User Interface</h2>
      <p>It was the team's decision to maximize the use
       of native components for both iOS and Android.</p>
    </Block>

    <Block align="center" pull>
      <Figure fluid={props.data.stoovoUI} />
    </Block>

    <Block align="left" pull>
      <p>
      After the initial launch, we spent months experimenting with the interface (colors, typography, paddings...), as well as refining the user onboarding experience. 
      During the latest redesign, we pushed hard towards making it much more easier to find & accept gigs through the Opportunities view, as well as marketing the features right after you'd create an account.
      </p>
    </Block>

    <Block align="right" pull>
      <p>
        To be able to iterate faster, towards the end of 2018 we decided that it was the right time to make a design system for the company (read UI) and started documenting all of its components and more importantly, gestures and motion design. Initially built on Sketch + Abstract but later ported over to Figma. Sweet setup! 
      </p>
    </Block>

    <hr />

    <Block>
      <h3>Epilogue</h3>
      <p>
        Looking back, Stoovo was one of the nicest experiences I could have asked for. Because it was a small & agile startup, I learned that sometimes design can have too much power and do some damage. The sweet spot is to find the right balance between business goals and user needs.
      </p>
    </Block>

    <Block align="right">
      <h3>&nbsp;</h3>
      <p>
        I had spent all of my tenure at Stoovo exploring, maintaining and designing. The result of my work can be seen by downloading the iOS app, which is in a public beta (U.S. only).
      </p>
    </Block>
  </Article>
);

export const query = graphql`
  query GatsbyImageStoovo {
    stoovoBrandingDrawing: file(relativePath: { eq: "stoovo/stoovo-branding-drawing.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    stoovoAppIcon: file(
      relativePath: { eq: "stoovo/stoovo-app-icon.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    stoovoIterations: file(
      relativePath: { eq: "stoovo/stoovo-iterations.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    stoovoUI: file(relativePath: { eq: "stoovo/stoovo-UI.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
